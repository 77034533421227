import { AxiosResponse } from 'axios';
import {
  takeEvery, put, fork, select,
} from 'redux-saga/effects';
import { getOrganizationData, getUserData } from 'services/cultivoInteligenteService';
import {
  getOrganizationSuccess, getOrganizationFailure,
} from './actionts';
import { OrganizationActionType, Organization } from './types';

function* getOrganization():any {
  try {
    const { jwt, properties } = yield select((state) => state.user);
    const { externalId, orgId } = yield select((state) => state.organization);
    
    const { user_id } = properties;

    const response: AxiosResponse<Organization> = yield getOrganizationData(
      {
        jwt,
        externalId,
      },
    );

    const responseUser: AxiosResponse<any> = yield getUserData(
      {
        jwt,
        organizationId: orgId,
        userId: user_id,
      },
    )

    yield put(getOrganizationSuccess(response.data,responseUser.data.user));
  } catch (error) {
    yield put(getOrganizationFailure());
  }
}

function* watchGetOrganizationRequest() {
  yield takeEvery(OrganizationActionType.GET_ORGANIZATION_REQUEST, getOrganization);
}

export const organizationSagas = [fork(watchGetOrganizationRequest)];
