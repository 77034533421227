import { V4ClassEnum } from 'types/v4ClassEnum';
import { format } from 'date-fns';
import { HistoricAnswer } from '../historics/types';
import { Organization } from '../organization/types';
import { Answer, Field, Form } from './types';

export function generateUpdateAnswersData(
  answers: Answer[],
  historicAnswers: HistoricAnswer[],
) {
  const answersData: { answer_id: number; value: string | string[] | null }[] =
    [];

  answers.forEach((answer, index) => {
    const newAnswer = {
      answer_id: historicAnswers[index].answer_id,
      value: answer.value?.includes('\t')
        ? answer.value.split('\t').slice(1)
        : answer.value,
    };
    answersData.push(newAnswer);
  });

  return {
    answers: answersData,
  };
}

function firsThreeLetters(text: string): string {
  if (text.length < 3) return text.toUpperCase();
  return text.slice(0, 3).toUpperCase();
}

export function gerenateAutoFieldValue({
  externalId,
  firstName,
  lastName,
}: {
  externalId: string;
  firstName: string;
  lastName: string;
}) {
  const now = new Date();
  const formattedDate = format(now, 'dd/MM/yy-HH:mm:ss');
  const threeLettersFirstName = firsThreeLetters(firstName);
  const threeLettersLastName = firsThreeLetters(lastName);
  return `${externalId}-${threeLettersFirstName}${threeLettersLastName}-${formattedDate}`;
}

export function formatForm(
  form: Form,
  organization: Organization,
  externalId: string,
): Form {
  const newFields = form.fields.map((field) => ({
    ...field,
    items: field.v4_class
      ? organization[V4ClassEnum[field.v4_class]]
      : field.items,
  }));

  newFields.forEach((field) => {
    field.items = field.items?.filter((item) => {
      if (item.company_id) {
        return item.company_id?.toString() === externalId?.toString();
      }
      return true;
    });
  });

  return {
    ...form,
    fields: newFields,
  };
}

export function verifyNeedsCleanConditionalAnswersWhenEditing(
  answer: Answer,
  answersList: Answer[],
  fields: Field[],
): boolean {
  const existingAnswer = answersList.find(
    (ans: { field_id: any }) => ans.field_id === answer.field_id,
  );

  if (existingAnswer) {
    const answerField = fields.find(
      (field) => field.field_id === answer.field_id,
    );
    const isConditionalResponse = answerField?.component_depends?.length !== 0;

    if (!isConditionalResponse || existingAnswer.value === null) {
      return false;
    }

    return existingAnswer.value !== answer.value;
  }

  return false;
}

export function clearAnswersToNull(
  answers: Answer[],
  startIndex: number,
): Answer[] {
  const clearAnswers = answers.map((answer, index) => {
    if (index >= startIndex) {
      return {
        field_id: answer.field_id,
        value: null,
      };
    }
    return answer;
  });

  return clearAnswers;
}
