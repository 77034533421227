import { Organization, OrganizationActionType } from './types';

export const getOrganizationRequest = () => (
  {
    type: OrganizationActionType.GET_ORGANIZATION_REQUEST,
  }
);

export const getOrganizationSuccess = (organization: Organization, userData: any) => ({
    type: OrganizationActionType.GET_ORGANIZATION_SUCCESS,
    payload: { organization, userData },
  });

export const getOrganizationFailure = () => (
  {
    type: OrganizationActionType.GET_ORGANIZATION_FAILURE,
  }
);

export const setOrganizationIds = (externalId: string, orgId : string) => (
  {
    type: OrganizationActionType.SET_ORGANIZATION_IDS,
    payload: { externalId, orgId },
  }
);
