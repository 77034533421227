import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { ReactComponent as CopyLine } from 'assets/icons/copy_line.svg';
import { themeOptions } from 'assets/styles/theme';

interface FieldAutoGeneratedProps {
  defaultValue: string;
}

export default function FieldAutoGenerated({
  defaultValue,
}: FieldAutoGeneratedProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginInline: 30,
      }}
    >
      <h6
        style={{
          marginBottom: 4,
          color: themeOptions?.palette?.text?.secondary,
          fontWeight: themeOptions.typography.fontWeightRegular,
          fontSize: themeOptions.typography.caption.fontSize,
        }}
      >
        Código de lote
      </h6>
      <h4
        style={{
          marginBottom: 16,
          color: themeOptions?.palette?.text?.primary,
          fontWeight: themeOptions.typography.fontWeightMedium,
          fontSize: 18,
        }}
      >
        {defaultValue}
      </h4>
      <Button
        style={{ width: '100%' }}
        rightIcon={<CopyLine />}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(defaultValue);
          } catch (e) {
            console.error(`error during copy ${e}`);
          }
        }}
      >
        Copiar
      </Button>
    </div>
  );
}
